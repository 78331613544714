import React, { useState, useEffect, useMemo } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

import Header from "./Header";
import HomeHeader from "./subComponents/HomeHeader";
import Footer from "./Footer";
import CoursesCard from "./subComponents/CoursesCard";
import { getData } from "../utilities";

const Courses = ({ filter, setFilter }) => {
  const location = useLocation();
  const search = location.search; //useLocation().search;
  const queryParams = new URLSearchParams(location.search);
  const [courseData, setCourseData] = useState([]);

  const [loading, setLoading] = useState(true);

  let paramData = "";
  if (filter.level || filter.country) {
    queryParams.delete("universityId");
  }
  if (search) {
    paramData = search;
  }

  if (filter.level || filter.country) {
    paramData = `?filter=true&`;

    if (filter.level) {
      paramData = `${paramData}level=${filter.level}&`;
    }

    if (filter.level) {
      paramData = `${paramData}country=${filter.country}`;
    }
    // paramData = JSON.stringify(paramData);
    // paramData = "last char = " + paramData.charAt(paramData.length() - 1);
    // paramData = paramData.substring(paramData.length() - 1);
  } else if (filter.department) {
    paramData = `?filterBy=${filter.filterBy}&department=${filter.department}`;
    // console.log("filter applyed ******* paramData", paramData);
  }

  const getCoursesData = async () => {
    const { result, error } = await getData(
      `/api/course/requirement/list${paramData}`
    );

    const getObj = (
      image,
      courseName,
      universityName,
      countryName,
      tutionFee,
      applicationFee,
      inTake,
      duration,
      ETS,
      department,
      url,
      level
    ) => {
      return {
        image,
        courseName,
        universityName,
        countryName,
        tutionFee,
        applicationFee,
        inTake,
        duration,
        ETS,
        department,
        url,
        level,
      };
    };
    if (result) {
      const tempArray = [];
      result.body.list.map((item, index) => {
        const tempData = getObj(
          item.universityId.logo,
          item.coursetId.title,
          item.universityId.name,
          item.universityId.city,
          item.tutionFee,
          item.applicationFee,
          item.inTake,
          item.duration,
          item.ETS.toString(),
          item.coursetId.title,
          item.universityId.website,
          item.level
        );
        tempArray.push(tempData);
      });
      setCourseData([...tempArray]);
      setLoading(false);
    } else if (error) {
      setCourseData([]);
    }
  };
  useEffect(() => {
    getCoursesData();
  }, [paramData]);

  // console.log("course data page ****", courseData);

  return (
    <div className="courses">
      <div className="header">
        <Header courses />
        <HomeHeader filter={filter} setFilter={setFilter} />
      </div>
      <div className="coursesBody century-font">
        {loading && (
          <div className="w-100 text-center">
            <div className="spinner-border mt-5"></div>
            <p className="pt-3">No Record Yet !!!!</p>
            <p className="pt-3">Refresh the page to get all records</p>
          </div>
        )}
        {!loading && <CoursesCard coursesData={courseData} />}
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Courses;
