import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Header from "../Header";

import { getData } from "../../utilities";

const BlogDetail = () => {
  const location = useLocation();
  const [post, setPost] = useState([]);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const postId = location.pathname.split("/detail/")[1];
    getPost_(postId);
  }, []);

  const getPost_ = async (postId) => {
    const { result } = await getData(`/api/blog/detail/${postId}`);
    setPost(result.body.Blog);
  };

  const getAllBlogs = async () => {
    const { result, error } = await getData("/api/blog/list");
    let results = result.body.blog;
    if (results) {
      setPosts(results);
    } else {
      console.log("blogs error is", error);
    }
  };
  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <>
      <Header />
      <div className="container p-0 mb-4 pt-5">
        <div className="blog-detail-container pt-3">
          <div className="heading">{post.title}</div>
          <div className="text-left py-2">{post.date}</div>
          <img
            className="w-100 pt-1 blog-img"
            src={post.image}
            alt={`${post.title}`}
          />
          <p className="pt-4 blog-content"> {post.body}</p>
        </div>
        <div className="row blog-detail-container pt-4">
          <div className="col-12 heading"> Recent Posts</div>
          {posts.slice(0, 3).map((blog) => (
            <>
              <div className="col-12 col-md-6 col-lg-4 d-flex align-items-strech">
                <div
                  className="extra pointer"
                  onClick={() => {
                    window.location.href = `/detail/${blog._id}`;
                  }}
                >
                  <div className="blogCard w-100" key={blog._id}>
                    {console.log("blog", blog)}
                    <div className="w-100" style={{ height: "250px" }}>
                      <img
                        src={blog.image}
                        alt={blog.title}
                        className="w-100"
                        style={{
                          height: "250px",
                          borderRadius: "10px 10px 0 0",
                        }}
                      />
                    </div>
                    <div className="heading pt-2">
                      {blog.title.substring(0, 20)} ...
                    </div>
                    <div className="blogDescription pb-2">
                      {blog.body.substring(0, 200) + "..."}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
