import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, Button, TextField, FormControl } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import Header from "../Header";
import Footer from "../Footer";
import "./googleAPI.scss";

function InPersonCounselling() {
  let appointData = {
    name: "",
    email: "",
    phone: "",
    region: "",
    city: "",
    lastQualification: "",
    countryOfIntrest: "",
    fieldOfIntrest: "",
  };
  let history = useHistory();

  const [data, setData] = useState(appointData);
  const [dateValue, setDateValue] = useState(null);
  let {
    name,
    email,
    phone,
    region,
    city,
    lastQualification,
    countryOfIntrest,
    fieldOfIntrest,
  } = data;

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const appointment = new Date(dateValue.$d);
      let monthOfCompletion = appointment.toLocaleDateString("en-CA");

      let response = await fetch(
        "https://v1.nocodeapi.com/nome/google_sheets/GhqAVNLWPzxMSXmN?tabId=inpersonCounselling",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            [
              name,
              email,
              monthOfCompletion,
              phone,
              region,
              city,
              lastQualification,
              countryOfIntrest,
              fieldOfIntrest,
              new Date().toLocaleString(),
            ],
          ]),
        }
      );

      await response.json();
      setData({ ...data, name: "", agenda: "" });
      history.push("/");
    } catch (error) {
      console.log(error);
      history.push("/");
    }
  };
  return (
    <>
      <Header />
      <div className="ap-form">
        <Box
          className="w-50"
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          onSubmit={handleSubmit}
        >
          <h3 className="w-100 text-center py-3">InPerson Counselling</h3>
          <TextField
            className="w-100"
            label="Name"
            placeholder="Name"
            variant="outlined"
            name="name"
            value={name}
            onChange={handleChange}
            required
          />
          <TextField
            className="w-100"
            label="Email"
            placeholder="Email"
            variant="outlined"
            name="email"
            value={email}
            onChange={handleChange}
            required
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              renderInput={(props) => <TextField {...props} />}
              label="Year/Month of Completion"
              className="w-100"
              value={dateValue}
              onChange={(newValue) => {
                setDateValue(newValue);
              }}
            />
          </LocalizationProvider>
          <TextField
            className="w-100"
            label="Phone Number"
            placeholder="Phone Number"
            variant="outlined"
            name="phone"
            value={phone}
            onChange={handleChange}
            required
            // inputProps={{ type: "number" }}
          />
          <TextField
            className="w-100"
            label="Region"
            placeholder="Region"
            variant="outlined"
            name="region"
            value={region}
            onChange={handleChange}
            required
          />
          <TextField
            className="w-100"
            label="City"
            placeholder="City"
            variant="outlined"
            name="city"
            value={city}
            onChange={handleChange}
            required
          />
          <TextField
            className="w-100"
            label="Last Qualification"
            placeholder="Last Qualification"
            variant="outlined"
            name="lastQualification"
            value={lastQualification}
            onChange={handleChange}
            required
          />
          <TextField
            className="w-100"
            label="Country Of Intrest"
            placeholder="Country Of Intrest"
            variant="outlined"
            name="countryOfIntrest"
            value={countryOfIntrest}
            onChange={handleChange}
            required
          />
          <TextField
            className="w-100"
            label="Field Of Intrest"
            placeholder="Field Of Intrest"
            variant="outlined"
            name="fieldOfIntrest"
            value={fieldOfIntrest}
            onChange={handleChange}
            required
          />
          <div className="mt-3 text-center w-100">
            <Button className="w-100 p-2" type="submit" variant="outlined">
              Submit
            </Button>
          </div>
        </Box>
      </div>
      <Footer />
    </>
  );
}

export default InPersonCounselling;
