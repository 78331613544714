import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import uniLogo from "../../assets/images/uniLogo.png";
import locationLogo from "../../assets/images/locationLogo.png";
import Header_model_bg_img from "../../assets/images/comming-soon.webp";
let { Body } = Modal;

const CoursesCard = (props) => {
  // console.log("props course data", props.coursesData);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const universityPageLink = (universityName) => {
    let uniName = universityName.toLowerCase();
    uniName = uniName.replace(/\s/gm, "");
    uniName = uniName.replace(/\./g, "");
    return uniName;
  };
  // useEffect(() => {
  //   if (props.coursesData.length === 0) {
  //     window.location.reload();
  //   }
  // }, []);
  // const pageRelo

  return (
    <div className="coursesCard">
      {props.coursesData &&
        // {props.coursesData.length > 0 &&
        props.coursesData.map((item, index) => {
          return (
            <>
              <div className="fullCard">
                <div className="imageBox">
                  <img
                    src={item.image}
                    alt="univeristy logo"
                    className="img img-fluid pointer"
                    onClick={() => {
                      history.push(
                        `/university/${universityPageLink(item.universityName)}`
                      );
                    }}
                  />
                </div>
                <div className="restCard">
                  <div className="top">
                    <div className="col-12 col-sm-6 d-flex justify-content-start flex-column">
                      <div className="courseName">{item.courseName}</div>
                      <div className="d-flex">
                        <a
                          href={item.url}
                          target="_blank"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <div className="location d-flex align-items-center pointer">
                            <img
                              src={uniLogo}
                              alt=""
                              className="img img-fluid"
                            />
                            <span>{item.universityName}</span>
                          </div>
                        </a>
                        <div className="location">
                          <img
                            src={locationLogo}
                            alt=""
                            className="img img-fluid "
                          />
                          <span>{item.countryName}</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 mt-2 mt-md-0 mb-2  d-flex justify-content-end">
                      <Link
                        className="blackButton lh-40"
                        to={{
                          pathname: "enteryRequirement",
                          state: {
                            uniName: item.countryName,
                            courseName: item.courseName,
                          },
                        }}
                      >
                        Entry Requirement
                      </Link>
                      <button className="gradientButton">
                        {/* <a
                          href={item.url}
                          target="_blank"
                          style={{ textDecoration: "none", color: "black" }}
                        > */}
                        <Link
                          style={{ textDecoration: "none", color: "black" }}
                          to={{
                            pathname: "applyNow",
                            state: {
                              uniName: item.countryName,
                              courseName: item.courseName,
                            },
                          }}
                        >
                          Apply Now
                        </Link>
                        {/* </a> */}
                      </button>
                    </div>
                  </div>
                  <div className="bot mt-2">
                    <div className="d-flex flex-column justify-content-start">
                      <div className="text">
                        <b>TUTION FEE</b>
                      </div>
                      <div className="value">{item.tutionFee}</div>
                    </div>
                    <div className="d-flex flex-column justify-content-start">
                      <div className="text">
                        <b>APPLICATION FEE</b>
                      </div>
                      <div className="value">{item.applicationFee}</div>
                    </div>
                    <div className="d-flex flex-column justify-content-start">
                      <div className="text">
                        <b>IN TAKE</b>
                      </div>
                      <div className="value">{item.inTake + ","}</div>
                    </div>
                    <div className="d-flex flex-column justify-content-start">
                      <div className="text">
                        <b>DURATION (months)</b>
                      </div>
                      <div className="value">{item.duration + ","}</div>
                    </div>
                    <div className="d-flex flex-column justify-content-start">
                      <div className="text">
                        <b>ETS</b>
                      </div>
                      <div className="value">{item.ETS}</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      {props.coursesData.length === 0 && (
        <>
          <h1>No Data found</h1>
        </>
      )}
      <Modal className="custom-model-header" show={show} onHide={handleClose}>
        <Body>
          <img src={Header_model_bg_img} alt="modelImage" className="w-100" />
        </Body>
      </Modal>
    </div>
  );
};

export default CoursesCard;
