import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Lines from "./subComponents/Lines";
import Footer from "./Footer";
import { getData } from "../utilities";

import header1 from "../assets/images/header1.png";

const CountryPage = () => {
  const param = useParams();
  const [countryName, setCountryName] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const [countryId, setCountryId] = useState(null);
  const [details, setDetails] = useState(null);
  const [detailsToShow, setDetailsToShow] = useState();
  const [loading, setLoading] = useState(true);
  const getCountryName = async () => {
    const { result, error } = await getData("/api/country/list/");
    if (result) {
      const isCountryFound = result?.body?.country?.filter((item, index) => {
        let country_name = item.name.toLowerCase();
        country_name = country_name.replace(/\s/gm, "");
        country_name = country_name.replace(/\./g, "");
        if (country_name === param.countryname) {
        }
        return country_name === param.countryname;
      });
      if (isCountryFound[0]) {
        setCountryName(isCountryFound[0].name);
        setCountryId(isCountryFound[0]?._id);
      } else {
        setCountryName(param?.countryname?.toUpperCase());
        setCountryId(null);
        setDetails(null);
        setDetailsToShow(null);
        console.log("aaaaaaaaaa", isCountryFound);
        setLoading(false);
      }
    } else if (error) {
      console.log("error for country page is", error);
    }
  };
  const getCountryData = async () => {
    const { result, error } = await getData("/api/student_life/list");

    if (result) {
      const requiredCountry = result?.body?.data?.filter((country) => {
        return country.countryId?._id === countryId;
      });
      if (requiredCountry[0]) {
        setDetails({ ...requiredCountry[0] });
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (!loading) {
      if (activeTab === 1 && details) {
        setDetailsToShow(details?.student_life);
      } else if (activeTab === 2 && details) {
        setDetailsToShow(details?.scholarships_funding || "Coming Soon...");
      } else if (activeTab === 3 && details) {
        setDetailsToShow(details?.visa_guidance || "Coming Soon...");
      } else {
        setDetailsToShow("Coming Soon....");
      }
    }
  }, [details, activeTab, loading]);
  useEffect(() => {
    setLoading(true);
    getCountryName();
  }, [param]);
  useEffect(() => {
    if (countryId) {
      getCountryData();
    }
  }, [countryId]);

  return (
    <div className="countryPage">
      <div className="header">
        <div className="">
          <Header country={true} />
        </div>
        <div className="content">
          <div className="image">
            <img src={header1} alt="" className="img img-fluid blurImage" />
          </div>
          <div className="text">
            Study In {countryName || param.countryname.toUpperCase()}
            <Lines color={"yellow"} />
          </div>
        </div>
      </div>
      <div className="countryPageBody">
        <div className="tabs  ">
          <div
            className={`col-3 pointer ${activeTab === 1 ? "active" : ""}`}
            onClick={() => {
              setActiveTab(1);
            }}
          >
            Student Life In {countryName || param.countryname.toUpperCase()}
          </div>
          <div
            className={`col-3 pointer ${activeTab === 2 ? "active" : ""}`}
            onClick={() => {
              setActiveTab(2);
            }}
          >
            Scholarships {"&"} Funding
          </div>
          <div
            className={`col-3 pointer ${activeTab === 3 ? "active" : ""}`}
            onClick={() => {
              setActiveTab(3);
            }}
          >
            {countryName || param.countryname.toUpperCase()} Visa Guidance
          </div>
        </div>
        <div className="restBody">
          {countryName && (
            <>
              <div className="heading">
                {activeTab === 1 && <>Student Life In {countryName}</>}
                {activeTab === 2 && <>Scholarships and Fundings</>}
                {activeTab === 3 && <>Visa Guidance</>}
              </div>
            </>
          )}

          <div className="content mt-2" style={{ textAlign: "justify" }}>
            {loading && (
              <div className="w-100 text-center m-4">
                <div className="spinner-border"></div>
              </div>
            )}
            {!loading && <>{detailsToShow}</>}
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default CountryPage;
