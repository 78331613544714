import React, { useState, useEffect } from "react";
import { FacebookRounded } from "@mui/icons-material";
import { getData } from "../utilities";
const Footer = () => {
  const [topUniversities, setTopUniversities] = useState([]);
  const [topCourses, setTopCourses] = useState([]);

  useEffect(() => {
    getTopUniversities();
    getTopCourse();
  }, []);

  async function getTopCourse() {
    const response = await getData("/api/course/list");
    let { list } = response.result.body;
    setTopCourses(list);
  }
  async function getTopUniversities() {
    const response = await getData("/api/university/list");
    let { university } = response.result.body;
    setTopUniversities(university);
  }

  function openInNewTab(url) {
    window.open(url, "_blank").focus();
  }
  return (
    <>
      <div className="footer w-100">
        <div className="top row d-none d-md-flex">
          <div className="col-3">
            <div className="h3">STUDY ABROAD WITH APPLY NOW GLOBAL</div>
            <div>
              Get the facility of mock interview, and get all the other
              important tips and techniques to successfuly complete the process
              of visa.
            </div>
          </div>
          <div className="col-3">
            <div className="bold">Top Universities</div>
            {topUniversities
              ? topUniversities.slice(0, 5).map((uni) => {
                  return (
                    <div className="mt-2">
                      <a
                        className="footer-link"
                        onClick={() => openInNewTab(uni.website)}
                        href="#"
                      >
                        {uni.name}
                      </a>
                    </div>
                  );
                })
              : "Loading"}
          </div>
          <div className="col-3">
            <div className="bold">Top Courses</div>
            {topCourses.slice(0, 5).map((course) => {
              return <div className="mt-2">{course.title}</div>;
            })}
          </div>
          <div className="col-3">
            <div className="h3">FOLLOW US</div>

            <div>
              <img
                src="https://png.pngitem.com/pimgs/s/511-5114754_facebook-logo-png-white-transparent-png.png"
                alt="facebook from free icons png"
                className="social img img-fluid"
              />
              {/* <FacebookRounded
                onClick={() => openInNewTab("https://www.facebook.com/")}
              /> */}
              <img
                onClick={() => openInNewTab("https://twitter.com/")}
                src="https://w7.pngwing.com/pngs/130/279/png-transparent-twitter-icon-computer-icons-logo-twitter-miscellaneous-leaf-branch.png"
                alt="Twitter free icons png"
                className="social img img-fluid"
              />
              <img
                onClick={() => openInNewTab("https://youtube.com/")}
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfD3_d4sm31WD9nugf-EKIcMztRX76BE84AA&usqp=CAU"
                alt="YouTube from free icons png"
                className="social img img-fluid"
              />
              <img
                onClick={() => openInNewTab("https://www.instagram.com/")}
                src="https://simg.nicepng.com/png/small/786-7864960_instagram-facebook-and-instagram-icon-white-transparent.png"
                alt="Instagram from freeiconspng"
                className="social img img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="bot row border border-light">
          <div className="w-100">
            &#169;Copyright 2022 ApplyNowGlobal | Developed by{" "}
            <a
              href="https://www.aimodsol.com/"
              target="_blank"
              style={{ textDecoration: "none", color: "white" }}
            >
              AIMODSOL
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
