import axios from "axios";

// export const baseURL = "http://localhost:4000";
// export const baseURL = "https://international-studies-apis.herokuapp.com";
export const baseURL = "https://colorful-rose-barracuda.cyclic.app";

export const getData = async (url) => {
  const response = await axios.get(`${baseURL}${url}`);
  if (response.status === 200) {
    return { result: response.data };
  } else {
    return { error: response };
  }
};
